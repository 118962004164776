@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,600);
/* Constants */
/* Common styles */
* {
  box-sizing: border-box; }

button {
  border: 0 none;
  outline: 0 none; }

html {
  height: 100%; }

body {
  height: 100%;
  outline: 0;
  font: 14px 'Open Sans', 'Helvetica Neue', Arial, serif; }

a {
  color: #000;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

/* IE < 8 */
.ui-element, .left-sidebar-toggle span, .player-close, span.player-fullscreen, span.player-unfullscreen {
  font-size: 16px;
  color: #424242;
  transition: 0.2s ease-in all; }
  .ui-element:hover, .left-sidebar-toggle span:hover, .player-close:hover, span.player-fullscreen:hover, span.player-unfullscreen:hover {
    color: #7d7d7d; }

.archive-arrow {
  line-height: 3.2em;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.5); }

/* Top panel */
.top-panel {
  position: absolute;
  right: 0;
  left: 0;
  height: 56px;
  background-color: #fff;
  z-index: 3;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.31); }

.top-menu {
  list-style: none;
  font-weight: 600;
  text-transform: lowercase; }

.top-menu-items-wrapper {
  float: right;
  margin-top: 8px; }

.top-menu-wrapper {
  display: inline-block;
  margin-right: 40px; }

.login-wrapper {
  display: inline-block;
  margin-right: 20px; }

.top-menu-logo-wrapper {
  float: left;
  display: inline-block;
  margin-left: 20px;
  margin-top: 12px;
  line-height: 2.4em; }
  .top-menu-logo-wrapper span {
    font-size: 22px;
    font-weight: 600;
    color: #2c8fdc;
    line-height: 1em; }

.login-wrapper .login, .login-wrapper .logout {
  color: #424242;
  border-bottom: 1px dashed #424242;
  cursor: pointer;
  line-height: 2.6em; }
  .login-wrapper .login:hover, .login-wrapper .logout:hover {
    color: #7d7d7d;
    border-bottom: 1px dashed #7d7d7d; }

.login-wrapper a:active {
  text-decoration: none; }

.login-wrapper .login-name {
  margin-right: 10px;
  color: #2c8fdc;
  font-size: 16px; }

/* Left slide panel */
.left-sidebar-wrapper {
  position: absolute;
  top: 56px;
  bottom: 0;
  width: 500px;
  padding: 22px 0 0 19px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.31);
  transition: 0.2s ease-in all; }

.search {
  width: 452px; }

.left-sidebar {
  height: 88%; }

.left-sidebar-toggle {
  position: absolute;
  width: 15px;
  height: 40px;
  top: 46%;
  left: 500px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.31), -1px 0px 1px #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  transition: 0.2s ease-in all;
  z-index: 2; }
  .left-sidebar-toggle span {
    line-height: 2.6; }

.left-sidebar-toggle.left-sidebar-close {
  left: 6px; }

.left-sidebar-wrapper.left-sidebar-open {
  left: 0; }

.left-sidebar-wrapper.left-sidebar-close {
  left: -495px; }

.preview-image-wrapper {
  display: inline-block;
  margin: 0 12px 9px 0;
  position: relative;
  width: 220px;
  height: 124px;
  overflow: hidden; }
  .preview-image-wrapper img {
    width: 220px;
    height: 124px;
    cursor: pointer;
    transition-property: width, height; }

.camera-action-panel {
  background-color: rgba(69, 69, 69, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 4px; }

.preview-image-camera-title {
  font-size: 12px;
  color: #fff;
  padding: 0 0 0 5px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6), 0 -1px 1px rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 3px; }

/* Scroller */
.scroller {
  overflow-y: scroll; }

.scroller::-webkit-scrollbar {
  width: 0; }

.scroller__track {
  display: none;
  position: absolute;
  right: 5px;
  top: 78px;
  bottom: 4px;
  width: 11px; }

._baron .scroller__track {
  display: block; }

.scroller__bar {
  position: absolute;
  z-index: 1;
  right: 1px;
  width: 7px;
  background: #2c8fdc; }

/* Player */
.player-container {
  width: 800px;
  background-color: #fff;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 27px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.31);
  border-radius: 1px; }

.player-container-fullscreen {
  width: 100%;
  height: 100%; }

.player {
  width: 743px;
  height: 405px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative; }
  .player .archive-next,
  .player .archive-previous {
    width: 100px;
    height: 100px;
    top: 130px;
    position: absolute;
    background-color: rgba(90, 90, 90, 0.34);
    z-index: 2;
    cursor: pointer;
    text-align: center; }
  .player .icon-arrow-left,
  .player .icon-arrow-right {
    position: absolute;
    top: 32px;
    font-size: 32px;
    color: rgba(255, 255, 255, 0.58); }
  .player .archive-previous {
    left: 0; }
  .player .archive-next {
    right: 0; }

div.player-fullscreen {
  width: 100% !important;
  height: 75vh !important; }

iframe.player-fullscreen {
  width: 100% !important;
  height: 85vh !important; }

.player-title {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9); }

.player-close {
  float: right;
  cursor: pointer;
  font-size: 18px; }

span.player-fullscreen {
  float: right;
  cursor: pointer;
  font-size: 18px; }

span.player-unfullscreen {
  display: none;
  float: right;
  cursor: pointer;
  font-size: 18px; }

.player-container .player-description {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.73);
  margin-bottom: 5px; }

.player-container:last-child {
  padding-bottom: 5px; }

.player-download-archive a {
  border-bottom: 1px dotted #424242; }
  .player-download-archive a:hover {
    color: #7d7d7d;
    border-bottom: 1px dotted #7d7d7d; }

/* Map */
.map-container {
  position: absolute;
  top: 56px;
  right: 0;
  left: 0;
  bottom: 0; }

#map {
  width: 100%;
  height: 100%;
  display: block; }

/* Modal window */
@media (min-width: 768px) {
  .modal-dialog {
    margin: 100px auto 0 auto; } }

.modal-content {
  box-shadow: none;
  border: none;
  border-radius: 0; }

/*
 * Auth form
 */
.auth {
  padding: 28px;
  width: 380px;
  background-color: #fff; }
  .auth .auth-title {
    font-size: 24px;
    text-align: center;
    color: #4b4b4b; }
  .auth form, .auth .org-choose {
    margin-top: 20px; }
  .auth button {
    width: 100%; }
  .auth .org-choose, .auth .building-choose {
    display: block;
    margin-bottom: 15px; }
  .auth .login-by-code {
    margin-bottom: 15px; }

.auth-error, .auth-success {
  height: 40px;
  padding: 6px 12px;
  line-height: 1.8;
  margin-top: 10px; }

.auth-error {
  border: 1px solid #e63138;
  color: #e63138; }

.auth-success {
  border: 1px solid #20af52;
  color: #20af52; }

.auth-wrapper {
  width: 100%; }

.login-type.animate-enter,
.login-type.animate-leave {
  transition: 3000ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all; }

.login-type.animate-enter {
  opacity: 1; }

.login-type.animate-enter.animate-enter-active {
  opacity: 0; }

.login-type.animate-leave {
  opacity: 0; }

.login-type.animate-leave.animate-leave-active {
  opacity: 1; }

.login-loader {
  background-image: url(../img/loader.gif);
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  position: relative;
  left: 43%; }

.info-loader {
  background-image: url(../img/info-loader.gif);
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  position: relative;
  left: 43%; }

/* Alert */
.alert {
  border-radius: 0; }

.alert-success {
  border-color: #ACD887; }

/* Form */
.form-group {
  margin-bottom: 15px; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  outline: 0;
  color: #4b4b4b;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .form-control:focus {
    border-color: #71B6EC;
    box-shadow: none;
    -webkit-box-shadow: none; }

.help-block {
  display: block;
  margin-top: 8px;
  color: #e63138; }

.ng-valid-parse.ng-invalid {
  border-color: #e63138; }

.btn {
  color: #fff;
  height: 40px;
  border-radius: 0;
  font-size: 16px;
  transition: background-color 0.2s ease; }
  .btn:hover {
    cursor: pointer; }

.btn-submit {
  background-color: #2c8fdc; }
  .btn-submit:hover {
    background-color: #1d76ce; }

.btn-submit[disabled]:hover {
  background-color: #2c8fdc; }

.btn[disabled] {
  cursor: not-allowed;
  opacity: 1;
  filter: alpha(opacity=100);
  box-shadow: none; }
  .btn[disabled]:hover {
    color: #fff; }

.btn-default, .btn-in-player {
  border: 1px solid #ccc;
  background-color: #fff;
  color: #424242;
  text-align: left;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  .btn-default .caret, .btn-in-player .caret {
    float: right;
    margin-right: 5px;
    margin-top: 7px; }

.btn-in-player {
  width: 200px;
  text-align: center; }

.btn-default-open {
  border-color: #71B6EC; }

.login-org-wrapper {
  margin-top: 15px; }

.btn-login-org {
  background-color: #79589f;
  font-size: 16px; }
  .btn-login-org:hover {
    background-color: #6e4f94; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

/* Popup */
.popup-overlay {
  display: none;
  background: black;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000; }

.popup-content, .player-wrapper {
  display: none;
  position: absolute;
  top: 0;
  z-index: 10001; }

.player-wrapper-fullscreen {
  width: 100% !important;
  height: auto;
  margin: 56px 0px 0px 0px !important;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px !important; }

.popup-content-tmp {
  display: none; }

.btn-group {
  position: relative;
  display: inline-block;
  margin-bottom: 10px; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .dropdown-menu li a {
    display: block;
    border: none;
    padding: 8px; }
    .dropdown-menu li a:hover {
      border: none;
      color: #424242;
      background-color: #efefef; }

/* Datetime picker */
[date-picker] span {
  border-radius: 0; }

[date-picker] .active.active {
  background-image: none;
  background-color: #1d76ce; }

[date-picker] .active:hover {
  background-color: #2c8fdc; }

[date-picker] .now {
  background-image: none;
  background-color: #6e4f94; }
  [date-picker] .now:hover {
    background-color: #79589f; }

.counters {
  position: absolute;
  bottom: 5px;
  right: 5px; }
  .counters p {
    font-size: 12px;
    margin-bottom: 3px;
    padding-left: 2px; }
  .counters .google-play-counter img {
    height: 40px; }
  .counters .krs-ix-counter {
    display: inline; }

/*# sourceMappingURL=client.css.map */
